.hero {
  position: relative;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 20px;
  background-color: var(--bg-dark);
}

.hero-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.hero-title {
  position: relative;
  @include fluid-type($min_width, $max_width, 40px, 90px);
  color: white;
  z-index: 10;
  font-weight: 600;
  max-width: 1200px;
  text-align: right;

  &[data-shadow="text"] {
    text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5), 0 5px 20px rgba(0, 0, 0, 0.5);
  }

  .smaller {
    @include fluid-type($min_width, $max_width, 20px, 40px);
    display: inline-block;
    background-color: var(--color-secondary);
    text-shadow: none;
    padding: 0.7rem 1rem;
  }
}

.hero-detail {
  position: relative;
  background-color: var(--bg-dark);
  width: 100%;
  height: 100%;
  max-height: 800px;
  aspect-ratio: 3/2;

  &[data-size="full"] {
    height: 100vh;
    max-height: initial;
    aspect-ratio: initial;

    @include media-breakpoint-down(lg) {
      height: initial;
      aspect-ratio: 5/3;
    }
  }

  .image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.intro-textwrap {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  flex-flow: column wrap;
  .intro-hero {
    color: white;
    text-shadow: 3px 2px 30px #000000;

    @include fluid-type($min_width, $max_width, 40px, 74px);
    font-weight: 600;

    &-text {
      max-width: 640px;
      color: #ffffff;
      text-shadow: 3px 2px 7px #000000;
    }
  }
}
