.counter-grid {
  $this: &;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  &.uno {
    grid-template-columns: repeat(1, 1fr);
  }
  &.trio {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: var(--bg-light);
    text-align: center;

    @include media-breakpoint-down(sm) {
      aspect-ratio: inherit;
    }

    &[data-bg="border"] {
      background-color: white;
      border: 1px solid var(--border-light);
    }

    &[data-bg="white"] {
      background-color: white;
    }

    &[data-bg="primary"] {
      background-color: var(--bg-primary);
    }

    &[data-bg="secondary"] {
      background-color: var(--bg-secondary);
    }

    &[data-bg="dark"] {
      background-color: var(--bg-dark);
      color: white;
    }

    &[data-bg="light"] {
      background-color: var(--bg-light);
    }
    &[data-bordercolor="dark"] {
      border-top: 0.5rem var(--bg-dark) solid;
    }

    &[data-bg="primary"],
    &[data-bg="dark"] {
      #{$this}__number {
        --title: white;
      }
      #{$this}__unit {
        --title: white;
      }
      #{$this}__text {
        --text: white;
      }
    }
  }
  &__icon {
    flex: 0 0 auto;
    width: 80px;
    height: auto;
    margin-block-end: 1rem;
  }

  &__number,
  &__unit,
  &__intro {
    //font-size: 40px;
    margin-block-end: 0.5rem;
    margin: 0 0 1rem 0;
    padding: 0;
    @include fluid-type($min_width, $max_width, 32px, 40px);
    font-weight: 500;
    line-height: 1.3;
    color: var(--title);
  }

  &__text {
    font-size: 16px;
  }

  &[data-usage="alternate"] {
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(3, 1fr);
    }
  
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    #{$this}__icon {
      flex: 0 0 auto;
      width: 100%;
      height: auto;
      margin-block-end: 1rem;
    }

    #{$this}__number,
    #{$this}__unit,
    #{$this}__intro {
      //font-size: 40px;
      margin-block-end: 0.5rem;
      margin: 0 0 1rem 0;
      padding: 0;
      @include fluid-type($min_width, $max_width, 42px, 64px);
      font-weight: 600;
      line-height: 1.3;
      color: var(--title);
    }

    #{$this}__text {
      font-size: 16px;
    }
  }

  &[data-usage="onecol"] {
    grid-template-columns: repeat(1, 1fr);

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    #{$this}__item {
      background-color: transparent;
      padding-block-end: 1rem;
      aspect-ratio: inherit;
      align-items: flex-end;
      gap: 0;
    }

    #{$this}__icon {
    }

    #{$this}__number,
    #{$this}__unit,
    #{$this}__intro {
    }
    #{$this}__intro {
      color: var(--color-secondary);
      @include fluid-type($min_width, $max_width, 22px, 26px);
    }

    #{$this}__text {
    }
  }
}
