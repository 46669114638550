.footer {
    padding-block: 4rem;
	text-align: center;

	&__list {
		display: flex;
		justify-content: center;
        margin: 0;
        margin-block-end: .5rem;
		padding: 0;
		list-style: none;	  
        
        @include media-breakpoint-down(md) {
            margin-block-end: .25rem;
        }
	}

    &__item {
        position: relative;
        margin-inline-end: 0.5rem;
        font-weight: 400;

        &:not(:last-child) {
            &::after {
                content: "/";
                position: relative;
                display: inline-block;
                margin-inline-start: 0.5rem;
            }
        }
    }

    &__link {
        @include fluid-type($min_width, $max_width, 16px, 20px);
        color: var(--text);
        text-decoration: none;

        &:hover {
            color: var(--meta);
        }
        &--newsletter {
            background-image: url(../img/icon/bullhorn-solid.svg);
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 20px;
            padding-right: 25px;
        }
    }

    &__copy {
        color: var(--meta);
        font-size: 16px;
                
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }

        a {
            color: var(--meta);
            text-decoration: none;
        }
    }
}
