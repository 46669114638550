:root {
  --toggle-btn: var(--color-secondary);
  --toggle-icon: white;
  --toggle-btn-current: var(--color-tertiary);
  --toggle-icon-current: white;
}

.menu-toggle {
  cursor: pointer;
  position: fixed;
  inset: 40px 40px auto auto;
  width: 80px;
  height: 80px;
  background-color: var(--toggle-btn);
  border-radius: 50%;
  box-shadow: 0 10px 30px rgba(black, 0.15);
  border: 0;
  z-index: 2000;

  @include media-breakpoint-down(sm) {
    inset: 20px 20px auto auto;
    width: 40px;
    height: 40px;
  }

  span {
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--toggle-icon);

    &:nth-child(1) {
      transform: translate(-50%, -9px);
    }

    &:nth-child(2) {
      transform: translate(-50%, 0);
    }

    &:nth-child(3) {
      transform: translate(-50%, 9px);
    }

    @include media-breakpoint-down(sm) {
      width: 17px;
      height: 2px;

      &:nth-child(1) {
        transform: translate(-50%, -6px);
      }

      &:nth-child(2) {
        transform: translate(-50%, 0);
      }

      &:nth-child(3) {
        transform: translate(-50%, 6px);
      }
    }
  }
}

.menu {
  display: grid;
  place-items: center;
  position: fixed;
  inset: 0 auto 0 120%;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  box-shadow: 0 0 100px rgba(black, 0.1);
  z-index: 1000;
  overflow: scroll;
}

.menu-nav {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  align-items: flex-start;
  padding: 1rem;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  
  @include media-breakpoint-up(md) {
    padding: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    position: relative;
    left: 4rem;
    padding: 0.6rem;
    opacity: 0;

    &:not(:last-child) {
      margin-bottom: 0rem;
    }

    @include media-breakpoint-down(md) {
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &--full {
      margin-block-start: 2rem;
      grid-column: 1/-1;
    }
  }

  &__pdf {
    display: inline-block;
    padding: 0.25rem 1rem;
    @include fluid-type($min_width, $max_width, 14px, 18px);
    color: white;
    background-color: var(--btn-primary);
    border-radius: 50vw;
    text-decoration: none;
    @include effect();

    &:hover {
      background-color: var(--btn-primary-hover);
    }
  }

  &__link {
    position: relative;
    @include fluid-type($min_width, $max_width, 16px, 18px);
    font-weight: 400;
    color: var(--text);
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: var(--color-secondary);
      transform-origin: left;
      animation: lineOut 0.3s forwards;
      @include effect();
    }

    &:hover {
      // color: var(--color-secondary);

      &::after {
        animation: lineIn 0.3s forwards;
        @include effect();
      }

      @keyframes lineIn {
        from {
          transform: scaleX(0);
        }

        to {
          transform: scaleX(1);
        }
      }

      @keyframes lineOut {
        from {
          transform: scaleX(1);
        }

        to {
          transform: scaleX(0);
        }
      }
    }

    // &[aria-current="page"] {
    //   color: var(--color-secondary);
    // }
  }
}

.menu-pdf {
  position: absolute;
  inset: auto 40px 40px auto;
  display: inline-block;
  padding: 0.25rem 1rem;
  @include fluid-type($min_width, $max_width, 14px, 16px);
  color: white;
  font-weight: 600;
  background-color: var(--btn-primary);
  border-radius: 50vw;
  text-decoration: none;
  @include effect();

  &:hover {
    background-color: var(--btn-primary-hover);
  }

  @include media-breakpoint-down(sm) {
    inset: auto 20px 20px auto;
  }
}

.menu-logo {
  position: absolute;
  inset: 50px auto auto 40px;
  margin-right: 2rem;

  img {
    width: 240px;
    height: auto;
  }

  @include media-breakpoint-down(sm) {
    inset: 30px auto auto 20px;

    img {
      width: 200px;
    }
  }
}

.category_item {
  font-weight: 600;
  border-bottom: 3px var(--color-secondary) solid;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 50px;
    padding-right: 1rem;
    box-sizing: content-box;
  }
}

.highlighted_item {
  background-color: var(--color-primary-4);
  color: #ffffff;

  @include media-breakpoint-down(md) {
    padding: 0 0.5rem;
  }

  a {
    font-weight: 600;
  }
}