.video {
    width: 100%;
    aspect-ratio: 16/9;

    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
}

