:root {
	--acc-btn: #f2f2f2;
	--acc-btn-hover: var(--btn-light-hover);
	--acc-btn-title: var(--color-primary);
	--acc-btn-icon: var(--title);
}

.accordion {
	$this: &;

	&__item--show #{$this}__info {
		height: auto;
		opacity: 1;
	}

	&__button {
		cursor: pointer;
		display: block;
		position: relative;
		background: none;
		border: 0;
		width: 100%;
		padding: 1.25rem 3rem 1.25rem 1.5rem;
		@include fluid-type($min_width, $max_width, 16px, 20px);
		font-weight: 600;
		line-height: 1.4;
		color: var(--acc-btn-title);
		text-align: left;
		background-color: var(--acc-btn);
		border-radius: 2rem;
		@include effect();

		&::after {
			align-self: flex-start;
			flex-shrink: 0;
			position: absolute;
			top: 1.5rem;
			right: 1.75rem;
			content: "";
			width: 16px;
			height: 16px;
			margin-inline-start: 1rem;
			background-color: var(--acc-btn-icon);
			mask-image: url("../img/icon/icon-chevron-down.svg");
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 16px;
		}

		&:hover {
			color: var(--acc-btn-title);
			background-color: var(--acc-btn-hover);

			&::after {
				mask-image: url("../img/icon/icon-chevron-down.svg");
			}
		}
	}

	&__info {
		height: 0;
		opacity: 0;
		padding: 0 1.5rem;
		background-color: transparent;
		overflow: hidden;
		@include effect();
		
		@include media-breakpoint-down(sm) {
			padding: 0 0.5rem;
		}
	}

	&__item {
		margin-block-end: 1rem;

		&#{$this}__item--show {
			#{$this}__button {
				color: var(--acc-btn-title);
				background-color: var(--acc-btn-hover);

				&::after {
					mask-image: url("../img/icon/icon-chevron-up.svg");
				}
			}

			#{$this}__info {
				padding: 2rem 1.5rem 3rem;
				
				@include media-breakpoint-down(sm) {
					padding: 2rem 0.5rem 3rem;
				}
			}
		}
	}

	&[data-content="primary"] {
		--acc-btn: var(--btn-primary);
		--acc-btn-hover: var(--btn-primary-hover);
		--acc-btn-title: white;
		--acc-btn-icon: white;
	}
	&[data-content="secondary"] {
		--acc-btn: var(--btn-secondary);
		--acc-btn-hover: var(--btn-secondary-hover);
		--acc-btn-title: white;
		--acc-btn-icon: white;
	}
}
