.swiper {
  .swiper-slide {
    p {
      margin-top: 1em;
    }
  }
  .slider-image {
    aspect-ratio: 3/2;
    background-color: var(--color-tertiary);
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      // pointer-events: none;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    color: var(--color-tertiary);
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 500px;
    padding: 1.5em;
    @include media-breakpoint-down(sm) {
      width: 25px;
      height: 25px; 
      padding: 1.1em;
    }

    &::after {
      font-size: 32px;
      font-weight: bold;
      @include media-breakpoint-down(sm) {
        font-size: 26px;
      }
    }

    &:hover {
      background-color: var(--color-tertiary);

      &::after {
        color: #ffffff;
      }
    }
  }

  .swiper-pagination {
    top: 1em;
    bottom: inherit;
    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      background-color: var(--bg-light);
      opacity: 1;
      @include media-breakpoint-down(sm) {
        width: 10px;
        height: 10px; 
      }

      &-active {
        background-color: var(--color-tertiary);
      }
    }
  }
}